import axios from 'axios'

export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return match[1] + match[2] + match[3]
  }
  return null
}

export const validateEmail = (email) => {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const submitApplication = (type, data, src) => {
  /*
        Types + Val Options:
            19 -> Life
                use_tobacco: Y,N
                gender: M,F
                insurance_type: final_expense, term
                insurance_amount: 50000
            21 -> medicare
                gender: M,F
                use_tobacco: Y,N
                age: Decimal 2
            27 -> health
                gender: Male, Female
                age: Int
                use_tobacco: Yes, No
                household_size: 1
                currently_insured: Yes, No

        Global Options:
            DOB: MM/DD/YYYY
            dob_month: Varchar
            dob_day: Varchar
            dob_year: Varchar
    */
  let phone = formatPhoneNumber(data.phone)
  let zip = data.zip.replace(/\D/g, '').slice(0, 5)

  if (zip.length !== 5) {
    return Promise.reject(new Error('Zip code must be 5 digits'))
  }
  if (phone.length !== 10) {
    return Promise.reject(new Error('Phone number must include your area code'))
  }
  if (!validateEmail(data.email)) {
    return Promise.reject(new Error('Please provide a valid email address'))
  }

  // Format the data how we need it
  let postData = {
    TYPE: 19,
    first_name: data.first,
    last_name: data.last,
    email: data.email,
    phone_1: phone,
    address_1: data.address,
    address: data.address,
    zip_code: zip,
    zip: zip,
    Zip: zip,
    gender: data.gender === 'male' ? 'M' : 'F',
    dob_month: parseInt(data.month),
    dob_day: parseInt(data.day),
    dob_year: parseInt(data.year),
    Age: Math.round(data.age),
    use_tobacco: data.tobacco === 'Yes' ? 'Y' : 'N',
    LeadiD: data.leadid,
    phone_last_4: phone.slice(-4),
    height_ft: data.ft,
    height_in: data.in,
    weight: parseInt(data.weight),
    household_size: '1',
    // estimated_household_income: "40000",
    currently_insured: data.existing ? data.existing : 'No',
    insurance_type: 'term',
    insurance_amount: parseInt(data.coverage),
    term_length: 'null',
    tcpa_site: 'https://lifeinsurancecompanion.com',
    landing_page: 'https://lifeinsurancecompanion.com',
    conditions: data.conditions ? data.conditions : 'none',
    Trusted_Form_Token:
      data.Trusted_Form_Token && data.Trusted_Form_Token.split('.com/')[1],
    Trusted_Form_Cert_URL: data.Trusted_Form_Cert_URL,
    ...(data.Sub_ID && { Sub_ID: data.Sub_ID }),
    ...(data.Pub_ID && { Pub_ID: data.Pub_ID }),
  }

  // Attach SRC if we have a value in store
  if (src !== '') {
    postData['SRC'] = src

    if (type === 'medicare' && data.other) {
      postData['SRC'] = src.replace('-Life', '-Med')
    }

    if (type === 'health' && data.other) {
      postData['SRC'] = src.replace('-Life', '-Health')
    }
  } else {
    if (type === 'medicare' && data.other) {
      // postData['SRC'] = 'CMI_MedicareCompanion_Organic'
      postData['SRC'] = 'CMI_LifeInsuranceCompanion_Organic-Med'
    } else {
      postData['SRC'] = 'CMI_LifeInsuranceCompanion_Organic'
    }
    if (type === 'health' && data.other) {
      postData['SRC'] = 'CMI_LifeInsuranceCompanion_Organic-Health'
    }
  }

  if (type === 'medicare' && data.other) {
    postData['TYPE'] = 21
    postData['use_tobacco'] = 'N'
    postData['insurance_amount'] = 20000
  }

  if (type === 'health' && data.other) {
    postData['TYPE'] = 27
    postData['use_tobacco'] = 'No'
    postData['insurance_amount'] = 20000
    postData['term_length'] = '15'
    postData['estimated_household_income'] = '40000'
    postData['gender'] = data.gender === 'male' ? 'Male' : 'Female'
  }

  // Determine insurance type and term length
  if (postData['Age'] < 50) {
    postData['insurance_type'] = 'term'
    postData['term_length'] = '15'
  } else if (
    postData['Age'] >= 50 &&
    postData['Age'] <= 55 &&
    postData['insurance_amount'] <= 50000
  ) {
    postData['insurance_type'] = 'final_expense'
  } else if (
    postData['Age'] >= 50 &&
    postData['Age'] <= 55 &&
    postData['insurance_amount'] > 50000
  ) {
    postData['insurance_type'] = 'term'
    postData['term_length'] = '15'
  } else if (postData['Age'] >= 55) {
    postData['insurance_type'] = 'final_expense'
  }

  // delete postData['SRC'];

  // Bypass the API call and just return the data format
  // Later we should make a param in the endpoint that
  // allows us to overwrite the test value
  // if (process.env.NODE_ENV === 'development') {
  //   return Promise.resolve(postData)
  // }

  const submitEndpointUrl =
    process.env.NODE_ENV === 'development'
      ? 'https://customer-dev.hivehq.co/v1/bobderdoo-lead-creation'
      : 'https://customer.hivehq.co/v1/bobderdoo-lead-creation'

  return axios
    .post(submitEndpointUrl, postData)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return Promise.reject(error)
    })
}
