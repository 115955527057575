import { applyMiddleware, createStore as reduxCreateStore, compose } from 'redux'
import { createLogger } from 'redux-logger'
import thunk from 'redux-thunk'
import * as Sentry from '@sentry/browser'
import createSentryMiddleware from 'redux-sentry-middleware'

import reducer from '../reducers'

Sentry.init({
  dsn: 'https://32f983015a864bf18a0c5820b425e646@sentry.io/2718081',
})

const middlewares = [thunk]

if (process.env !== 'production') {
  middlewares.push(createSentryMiddleware(Sentry))
  // Chrometools Redux Extension enabled
  middlewares.push(createLogger())
}

const windowGlobal = typeof window !== 'undefined' && window

const composeEnhancers = windowGlobal.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const createStore = () =>
  reduxCreateStore(reducer, composeEnhancers(applyMiddleware(...middlewares)))
export default createStore
